import React from 'react'
import { useParams, useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button';
import { Author} from './Author'
import { TimeAgo} from './TimeAgo'
// import { ReactionButtons } from './ReactionButtons'
// import { selectCrud1ById } from './crud1Slice.js'
import { Spinner } from '../../components/Spinner'
import { useGetPostQuery } from '../api/apiSlice'

export const ViewCrud1Form = () => {
  let navigate = useNavigate();
  let params = useParams()
  console.log('ViewCrud1Form')
  console.log(params)
  const { crud1Id } = params
  console.log(crud1Id)

  // const crud1 = useSelector(state => selectCrud1ById(state, crud1Id))
  // console.log(crud1)
  const { data: crud1hold, isFetching, isSuccess } = useGetPostQuery(crud1Id)
  console.log(crud1hold)
  console.log(isFetching)
  console.log(isSuccess)

  var crud1
  if (crud1hold === undefined) {
    crud1 = {}
  } else {
    crud1 = crud1hold.data
  }
  console.log(crud1)

  // if (!crud1) {
  //   return (
  //     <section>
  //       <h2>Crud1 not found!</h2>
  //     </section>
  //   )
  // }

  let content
  if (isFetching) {
    content = <Spinner text="Loading..." />
  } else if (isSuccess) {
    // <ReactionButtons crud1={crud1} />
    content = (
      <article className="post">
        <h2>{crud1.title}</h2>
        <div>
          <Author userId={crud1.user} />
          <TimeAgo timestamp={crud1.date} />
        </div>
        <p className="post-content">{crud1.content}</p>
        <Button
          variant="primary"
          type="button"
          onClick={() => {navigate("/crud1/edit/"+crud1.id);}}
        >
          Edit Crud Entry
        </Button>
      </article>
    )
  }
  return <section>{content}</section>
}
