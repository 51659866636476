// import React, { useEffect } from 'react'
// import React from 'react'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import { Author} from '../features/crud1/Author'
import { TimeAgo} from '../features/crud1/TimeAgo'
import { AddCrud1Form } from '../features/crud1/AddCrud1Form'
// import { ReactionButtons } from '../features/crud1/ReactionButtons'
// import { selectAllCrud, fetchCrud1s } from '../features/crud1/crud1Slice.js'
import { useGetPostsQuery } from '../features/api/apiSlice'

import { Spinner } from '../components/Spinner'

// <ReactionButtons crud1={crud1} />
const Crud1Excerpt = ({ crud1 }) => {
  return (
    <article className="post-excerpt" key={crud1.id}>
      <h3>{crud1.title}</h3>
      <p className="post-content">{crud1.content.substring(0, 100)}</p>
      <Author userId={crud1.user} />
      <TimeAgo timestamp={crud1.date} />
      <br />

      <br />
      <Link to={`/crud1/${crud1.id}`} className="button muted-button">
        View Crud Details
      </Link>
      <br />
      <br />
      <br />
    </article>
  )
}

export const Crud1 = () => {
  // const dispatch = useDispatch()
  // const crud1s = useSelector(selectAllCrud)
  // console.log('crud1')
  // console.log(crud1s)
  // const crud1Status = useSelector(state => state.crud1.status)
  // const error = useSelector(state => state.crud1.error)

  // const {
  //   data: crud1s,
  //   isLoading,
  //   isSuccess,
  //   isError,
  //   error
  // } = useGetPostsQuery()
  const hold = useGetPostsQuery()
  console.log('crud1')
  console.log(hold)
  const {
    data: crud1shold, // before api response, this is undefined, after it has data.data
    isLoading,
    isSuccess,
    isError,
    error
  } = hold
  console.log(crud1shold)
  console.log(isLoading)
  console.log(isSuccess)
  console.log(isError)
  console.log(error)

  // getting this compile error, and it should be fixed
  // src/routes/crud1.jsx
  // Line 67:7:  The 'crud1s' conditional could make the dependencies of useMemo Hook (at line 75) change on every render.
  // To fix this, wrap the initialization of 'crud1s' in its own useMemo() Hook  react-hooks/exhaustive-deps

  // var crud1s = (crud1shold === undefined) ? [] : crud1shold.data;
  // console.log(crud1s)

  const sortedCrud1s = useMemo(() => {
    if (crud1shold === undefined) {
      return []
    }
    const sortedCrud1s = crud1shold.data.slice()
    // Sort posts in descending chronological order
    sortedCrud1s.sort((a, b) => b.date.localeCompare(a.date))
    return sortedCrud1s
  }, [crud1shold])
  console.log(sortedCrud1s)

  // useEffect(() => {
  //   if (crud1Status === 'idle') {
  //     dispatch(fetchCrud1s())
  //   }
  // }, [crud1Status, dispatch])

  let content

  // if (crud1Status === 'loading') {
  if (isLoading) {
    content = <Spinner text="Loading..." />
  // } else if (crud1Status === 'succeeded') {
  } else if (isSuccess) {
    // Sort posts in reverse chronological order by datetime string
    // const orderedCrud1s = crud1s.slice().sort((a, b) => b.date.localeCompare(a.date))
    // content = orderedCrud1s.map(crud1 => (
    //   <Crud1Excerpt key={crud1.id} crud1={crud1} />
    // ))
    content = sortedCrud1s.map(crud1 => <Crud1Excerpt key={crud1.id} crud1={crud1} />)
  // } else if (crud1Status === 'failed') {
  } else if (isError) {
    // content = <div>{error}</div>
    content = <div>{error.toString()}</div>
  }

  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Crud1</h2>
      <React.Fragment>
        <AddCrud1Form />
        <br />
        <br />
        <br />
        <section className="posts-list">
          {content}
        </section>
      </React.Fragment>
    </main>
  )
}
