import React from "react";
import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import DisplayWidthHeightBreakpoint from './DisplayWidthHeightBreakpoint';
import MyNav from './MyNav';

import 'bootstrap/dist/css/bootstrap.css';

export default function App() {
  return (
    <>
      <MyNav />
      <Container>
        <h1 className="header">h1 header</h1>
        <h2 className="header">h2 header</h2>
        <div className="App">
          <header className="App-header">
            <p>
              app1.lownes.net
              <br />
              last update 01/22/22 at 07:47am
            </p>
            <DisplayWidthHeightBreakpoint />
          </header>
        </div>
        <nav
          style={{
            borderBottom: "solid 1px",
            paddingBottom: "1rem"
          }}
        >
          <Link to="/invoices">Invoices</Link> |{" "}
          <Link to="/expenses">Expenses</Link>
        </nav>
        <Outlet />
      </Container>
    </>
  );
}
