import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../api/client'

// const initialState = [
//   { id: '1', title: 'First Post! greg was here', content: 'Hello! greg was here', user: '0', date: '2022-02-05T12:20:31.225Z', reactions: {thumbsUp: 1, hooray: 2, heart: 3, rocket: 4, eyes: 5} },
//   { id: '2', title: 'Second Post greg was here', content: 'More text greg was here', user: '1', date: '2022-02-06T12:20:31.225Z', reactions: {thumbsUp: 10, hooray: 20, heart: 30, rocket: 40, eyes: 50} },
// ]
const initialState = {
  data:
  [
    // { id: '1', title: 'First Post! greg was here', content: 'Hello! greg was here', user: '0', date: '2022-02-05T12:20:31.225Z', reactions: {thumbsUp: 1, hooray: 2, heart: 3, rocket: 4, eyes: 5} },
    // { id: '2', title: 'Second Post greg was here', content: 'More text greg was here', user: '1', date: '2022-02-06T12:20:31.225Z', reactions: {thumbsUp: 10, hooray: 20, heart: 30, rocket: 40, eyes: 50} },
  ],
  status: 'idle',
  error: null,
}

const crud1Slice = createSlice({
  name: 'crud1',
  initialState,
  reducers: {
    crud1Added: {
      reducer(state, action) {
        state.data.push(action.payload)
      },
      prepare(title, content, user) {
        return {
          payload: {
            id: nanoid(),
            date: new Date().toISOString(),
            title,
            content,
            user,
            reactions: {thumbsUp: 0, hooray: 0, heart: 0, rocket: 0, eyes: 0},
          }
        }
      }
    },
    crud1Updated(state, action) {
      const { id, title, content, user } = action.payload
      const existingCrud1 = state.data.find(crud1 => crud1.id === id)
      if (existingCrud1) {
        existingCrud1.title = title
        existingCrud1.content = content
        existingCrud1.user = user
      }
    },
    reactionAdded(state, action) {
      const { crud1Id, reaction } = action.payload
      const existingCrud1 = state.data.find(crud1 => crud1.id === crud1Id)
      if (existingCrud1) {
        existingCrud1.reactions[reaction]++
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchCrud1s.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchCrud1s.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.data = state.data.concat(action.payload)
      })
      .addCase(fetchCrud1s.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addNewCrud1.fulfilled, (state, action) => {
        // We can directly add the new post object to our posts array
        state.data.push(action.payload)
      })
  }
})

export const fetchCrud1s = createAsyncThunk('crud1s/fetchCrud1s', async () => {
  const response = await client.get('http://localhost:8080/reduxdemo/posts')
  // const response = await client.get('https://api.lownes.net/reduxdemo/posts')
  return response.data
})

export const addNewCrud1 = createAsyncThunk(
  'crud1s/addNewCrud1',
  // The payload creator receives the partial `{title, content, user}` object
  async initialCrud1 => {
    // We send the initial data to the fake API server
    const response = await client.post('http://localhost:8080/reduxdemo/posts', initialCrud1)
    // const response = await client.post('https://api.lownes.net/reduxdemo/posts', initialCrud1)
    // The response includes the complete post object, including unique ID
    return response.data
  }
)

export const { crud1Added, crud1Updated, reactionAdded } = crud1Slice.actions
export default crud1Slice.reducer

export const selectAllCrud = state => state.crud1.data

export const selectCrud1ById = (state, crud1Id) =>
  state.crud1.data.find((crud1) => String(crud1.id) === crud1Id)
