import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { crud1Updated } from './crud1Slice'
import { selectCrud1ById } from './crud1Slice.js'

export const EditCrud1Form = () => {
  let navigate = useNavigate();
  let params = useParams()
  const { crud1Id } = params

  // const crud1 = useSelector(state =>
  //   state.crud1.find(crud1 => crud1.id === crud1Id)
  // )
  const crud1 = useSelector(state => selectCrud1ById(state, crud1Id))

  // need to call this before condition return below, else get this error
  // React Hook "useState" is called conditionally. React Hooks must be called in the exact same order in every component render. Did you accidentally call a React Hook after an early return?     react-hooks/rules-of-hooks
  const [title, setTitle] = useState(crud1?.title ?? "")
  const [content, setContent] = useState(crud1?.content ?? "")
  const dispatch = useDispatch()

  if (!crud1) {
    return (
      <section>
        <h2>Crud1 not found!</h2>
      </section>
    )
  }

  // setup for edit after id is confirmed to exist
  const onTitleChanged = e => setTitle(e.target.value)
  const onContentChanged = e => setContent(e.target.value)
  const canSave = Boolean(title) && Boolean(content) // && Boolean(userId)
  const onSaveCrud1Clicked = () => {
    if (title && content) {
      dispatch(
        crud1Updated({
          id: crud1Id,
          title,
          content
        })
      )
      navigate("/crud1/"+crud1Id);
    }
  }

  // return (
  //   <section>
  //     <article className="post">
  //       <h2>EDIT ENTRY {crud1.title}</h2>
  //       <p className="post-content">{crud1.content}</p>
  //     </article>
  //     <Button
  //       variant="primary"
  //       type="button"
  //       onClick={() => {navigate("/crud1/edit/"+crud1Id);}}
  //     >
  //       Edit Crud Entry
  //     </Button>
  //   </section>
  // )

  return (
    <section>
      <h2>Edit a Crud1</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Post Title:</Form.Label>
          <Form.Control
            id="postTitle"
            name="postTitle"
            as="input"
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={onTitleChanged}
          />
          <Form.Text className="text-muted">
            Title that appears for each post.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Content:</Form.Label>
          <Form.Control
            id="postContent"
            name="postContent"
            as="textarea"
            placeholder="Enter post content"
            value={content}
            onChange={onContentChanged}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="button"
          onClick={onSaveCrud1Clicked}
          disabled={!canSave}
        >
          Save Post Changes
        </Button>
      </Form>
    </section>
  )
}
