import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserById } from '../users/usersSlice.js'

export const Author = ({ userId }) => {
  // const author = useSelector(state =>
  //   state.users.find(user => user.id === userId)
  // )
  console.log('Author')
  console.log(userId)
  const author = useSelector(state => selectUserById(state, userId))
  console.log(author)
  return <span>by {author ? author.name : 'Unknown author'}</span>
}
