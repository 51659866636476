import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { crud1Added } from './crud1Slice'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { selectAllUsers } from '../users/usersSlice.js'
import { addNewCrud1 } from './crud1Slice'

export const AddCrud1Form = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [user, setUser] = useState('x')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const users = useSelector(selectAllUsers)
  const dispatch = useDispatch()
  const onTitleChanged = e => setTitle(e.target.value)
  const onContentChanged = e => setContent(e.target.value)
  const onUserChanged = e => setUser(e.target.value)
  // const onSaveCrud1Clicked = () => {
  //   if (title && content) {
  //     console.log(title, content, user)
  //     dispatch(crud1Added(title, content, user))
  //     setTitle('')
  //     setContent('')
  //     setUser('x')
  //   }
  // }

  const canSave =
    [title, content, user].every(Boolean) && addRequestStatus === 'idle'

  const onSaveCrud1Clicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        // OPEN check for NaN when converting user from string to number
        await dispatch(addNewCrud1({ title, content, user: parseInt(user) })).unwrap()
        setTitle('')
        setContent('')
        setUser('')
      } catch (err) {
        console.error('Failed to save the post: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  const usersOptions = users.map(user => (
    <option key={user.id} value={user.id}>
      {user.name}
    </option>
  ))

  return (
    <section>
      <h2>Add a New Crud1</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Post Title:</Form.Label>
          <Form.Control
            id="postTitle"
            name="postTitle"
            as="input"
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={onTitleChanged}
          />
          <Form.Text className="text-muted">
            Title that appears for each post.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Content:</Form.Label>
          <Form.Control
            id="postContent"
            name="postContent"
            as="textarea"
            placeholder="Enter post content"
            value={content}
            onChange={onContentChanged}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>User:</Form.Label>
          <Form.Select
            aria-label="select user"
            onChange={onUserChanged}
          >
            <option key='x' value='x'>Open this select menu</option>
            {usersOptions}
          </Form.Select>
        </Form.Group>

        <Button
          variant="primary"
          type="button"
          onClick={onSaveCrud1Clicked}
        >
          Save Post
        </Button>
      </Form>
    </section>
  )
}
