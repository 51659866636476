import { configureStore } from '@reduxjs/toolkit'
import crud1Reducer from './features/crud1/crud1Slice'
import usersReducer from './features/users/usersSlice'
import { apiSlice } from './features/api/apiSlice'

export default configureStore({
  reducer: {
    crud1: crud1Reducer,
    users: usersReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})
