import React from "react";

import { LinkContainer } from 'react-router-bootstrap'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'

export default function myNav() {
  return (
    <Navbar expand="lg" bg="primary" >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>App1</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link active={false}>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/crud1">
              <Nav.Link active={false}>Crud1</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link active={false}>About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about2">
              <Nav.Link active={false}>About2</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/api">
              <Nav.Link active={false}>Api</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
