import React, { useEffect, useState } from "react";

// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
// good article in displaying windows size

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const DisplayWidthHeightBreakpoint = () => {
  const { height, width } = useWindowDimensions();
  let breakpoint;
  if (width >= 1400) {
    breakpoint = "xxl: Extra extra large ≥1400px"
  } else if (width >= 1200) {
    breakpoint = "xl: Extra large ≥1200px"
  } else if (width >= 992) {
    breakpoint = "lg: Large ≥992px"
  } else if (width >= 768) {
    breakpoint = "md: Medium ≥768px"
  } else if (width >= 576) {
    breakpoint = "sm: Small ≥576px"
  } else {
    breakpoint = "xs: X-Small <576px"
  }
  return (
    <div>
      width: {width} ~ height: {height}
      <br />
      bootstrap breakpoint: {breakpoint}
    </div>
  );
};

export default DisplayWidthHeightBreakpoint;
