// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = {
    'Content-Type': 'application/json',
  }

  const config = {
    // mode: 'no-cors', // no-cors, *cors, same-origin
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    console.log("before call")
    console.log(endpoint)
    console.log(config)
    const response = await window.fetch(endpoint, config)
    data = await response.json()
    if (response.ok) {
      console.log('ok')
      console.log(response)
      // Return a result object similar to Axios
      return {
        status: response.status,
        data: data.data,
        headers: response.headers,
        url: response.url,
      }
    }
    console.log('error1')
    console.log(response)
    throw new Error(response.statusText)
  } catch (err) {
    console.log('error2')
    console.log(err)
    console.log(data)
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET',  })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}
