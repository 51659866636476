import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';
import store from './store'

import App from './App';
import Expenses from "./routes/expenses";
import Invoices from "./routes/invoices";
import Invoice from "./routes/invoice";
import { Crud1 } from "./routes/crud1";
import { ViewCrud1Form } from "./features/crud1/ViewCrud1Form";
import { EditCrud1Form } from "./features/crud1/EditCrud1Form";
import { fetchUsers } from './features/users/usersSlice'
import About from "./routes/about";
import About2 from "./routes/about2";
import Api from "./routes/api";

store.dispatch(fetchUsers())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} >
            <Route path="crud1">
              <Route path=":crud1Id" element={<ViewCrud1Form />} />
              <Route path="edit/:crud1Id" element={<EditCrud1Form />} />
              <Route index element={<Crud1 />} />
            </Route>
            <Route path="about" element={<About />} />
            <Route path="about2" element={<About2 />} />
            <Route path="api" element={<Api />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="invoices" element={<Invoices />}>
              <Route
                index
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Select an invoice</p>
                  </main>
                }
              />
              <Route path=":invoiceId" element={<Invoice />} />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
